<template>
    <div>
        <Navbar :page="`Novo contrato ${form.tipo === 'fornecedor' ? 'agentes de tratamento' : 'pessoas na empresa'}`" link="/contratos" nameLink="Gestão de contratos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <label v-if="inicialmenteDesativada" for="naoContemAvaliacao" class="block text-sm font-medium my-3">
                            <input type="checkbox" v-model="form.ativo" class="rounded-sm" id="naoContemAvaliacao">
                            <span class="ml-2">
                                Ativada <span class="text-red-500">(marque essa opção e salve para reativar a atividade)</span>
                            </span>
                        </label>
                        <div class="grid grid-cols-12 gap-6">
                            <div v-if="form.tipo === 'fornecedor'" class="col-span-12 md:col-span-4">
                                <label for="fornecedor" class="block text-sm font-medium"> Organização / Empresa </label>
                                <select v-model="form.fornecedor" name="fornecedor" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
                                </select>
                            </div> 
                            <div v-if="form.tipo === 'colaborador'" class="col-span-12 md:col-span-4">
                                <label for="colaborador" class="block text-sm font-medium"> Pessoa na empresa </label>
                                <select v-model="form.colaborador" name="colaborador" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-4">
                                <label for="name" class="block text-sm font-medium">Título do Contrato</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-3">
                                <label for="versao" class="block text-sm font-medium">Versão do Contrato</label>
                                <input v-model="form.versao" type="text" name="versao" id="versao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div>
                            <div class="col-span-12 md:col-span-2">
                                <label for="contrato_ativo" class="block text-sm font-medium mb-3">Contrato ativo ?</label>
                                <label :for="`contrato_ativo-Sim`" class="inline-flex items-center">
                                    <input type="radio" class="form-radio" :for="`contrato_ativo-Sim`" :name="`contrato_ativo`" v-model="form.contrato_ativo" :value="true">
                                    <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Sim</span>
                                </label>
                                <label :for="`contrato_ativo-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                    <input type="radio" class="form-radio" :for="`contrato_ativo-Nao`" :name="`contrato_ativo`" v-model="form.contrato_ativo" :value="false">
                                    <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-3">
                                <label for="data_contrato" class="block text-sm font-medium">Data do Contrato</label>
                                <datepicker v-model="form.data_contrato" :language="ptBR" name="data_contrato" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                                <label for="vencimento_contrato" class="block text-sm font-medium mt-2">Vencimento do Contrato</label>
                                <datepicker v-model="form.vencimento_contrato" :language="ptBR" name="vencimento_contrato" input-class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                            </div>
                            <div class="col-span-12 md:col-span-4">
                                <label for="data_contrato" class="block text-sm font-medium">Anexar Contrato</label>
                                <vue-dropzone ref="doc" id="doc" class="w-full mt-2" :options="dropzoneOptions" v-on:vdropzone-success="updateContrato">
                                </vue-dropzone>
                                <a v-if="this.form.anexo_contrato && this.form.anexo_contrato.filename" :href="`${this.url_api}/upload?mimetype=${this.form.anexo_contrato.mimetype}&filename=${this.form.anexo_contrato.filename}&folder=empresas`" target="_blank" rel="noreferrer" class="underline">
                                    <br/>
                                    <span class="font-semibold">Arquivo:</span>
                                    {{this.form.anexo_contrato.originalname}}
                                </a>
                            </div>
                            <div v-if="form._id" class="col-span-12 md:col-span-4">
                                <label class="block text-base text-blue-800 font-semibold mb-2">Contratos enviados:</label>
                                <div class="h-auto max-h-80 overflow-auto my-2">
                                    <div class="py-2 align-middle inline-block min-w-full">
                                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table class="min-w-full divide-y divide-gray-200">
                                                <thead class="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                            Email
                                                        </th>
                                                        <th scope="col" class="px-3 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">
                                                            Data Envio
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    <tr v-for="item in form.envios" :key="item._id">
                                                        <td class="px-3 py-2">
                                                            <div class="flex items-center">
                                                                <div class="text-sm text-gray-500">
                                                                    {{item.email ? item.email : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="px-3 py-2">
                                                            <div class="flex items-center">
                                                                <div class="text-sm text-gray-500">
                                                                    {{ item.dataEnvio | moment("DD/MM/YYYY HH:mm") }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <label for="enviarContrato" class="block text-base text-blue-800 font-semibold mb-2">Enviar Contrato:</label>
                                <div v-if="form.anexo_contrato && form.anexo_contrato.filename">
                                    <label for="descricaoContrato" class="block text-sm font-medium ">Descrição envio do contrato</label>
                                    <textarea rows="3" v-model="descricaoContrato" type="text" name="descricaoContrato" id="descricaoContrato" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" placeholder="Contrato #nomecontrato# gerado, acesse e visualize todos os seus termos"></textarea>
                                    <label for="emailContrato" class="block text-sm font-medium mt-2">
                                        Email responsável
                                    </label>
                                    <button @click="emailsContrato.push({email: ''})" type="button" class=" my-1 bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white flex py-1 px-3 rounded-lg text-sm_2 shadow-sm hover:shadow-md font-semibold justify-center">
                                        Adicionar
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 ml-1">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </button>
                                    <div v-for="(emailContrato, idx) in emailsContrato" :key="idx" class="grid grid-cols-12 gap-2 py-1">
                                        <div class="col-span-10">
                                            <input v-model="emailContrato.email" type="text" :name="`emailContrato-${idx}`" id="`emailContrato-${idx}`" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" placeholder="Email"/>
                                        </div>
                                        <div class="col-span-2">
                                            <button @click="emailsContrato.splice(idx, 1)" type="button" class="text-white hover:bg-red-600 bg-red-500 rounded py-2 px-2 mt-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <hr class="my-3"/>
                                    <button 
                                        @click="enviarContrato(form)"
                                        type="button" 
                                        class="text-white text-sm hover:bg-green-500 bg-green-700 rounded py-2 px-2 mt-3">
                                        Enviar Contrato
                                    </button>
                                </div>
                                <p v-else class="text-sm text-red-500 font-medium my-2">Nenhum contrato anexado</p>
                            </div>
                        </div>
                    </div>
                
                    <div class="flex">
                        <div class="py-3 text-left">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-4 py-3 text-right">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        vueDropzone,
        Datepicker,
    },
    data() {
        return {
            ptBR: ptBR,
            inicialmenteDesativada: false,
            fornecedores: [],
            pessoas: [],
            route: 'contratos',
            emailsContrato: [{ email: ''}],
            descricaoContrato: '',
            form: {
                nome: '',
                tipo: 'fornecedor',
                contrato_ativo: true,
                possui_nda: false,
                data_contrato: null,
                vencimento_contrato: null,
                anexo_contrato: null,
                assinatura: {
                    enviado: false,
                    assinado: false,
                    data_assinatura: null,
                },
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste o contrato aqui',
                dictRemoveFile: 'Remover'
            }),
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async enviarContrato (contrato) {
            this.$confirm({
                title: 'Enviar contrato',
                message: `Deseja enviar contrato para o responsável ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return;
                    const req = await this.$http.post(`/v1/${this.route}/enviarContrato`,{ _id: contrato._id, anexo_contrato: this.form.anexo_contrato, emails: this.emailsContrato, descricao: this.descricaoContrato });
                    if(!req.data.success) return this.$vToastify.error(req.data.err);

                    this.emailsContrato = [{ email: ''}];
                    this.descricaoContrato = '';
                    this.$vToastify.success("Enviado com sucesso!");

                    this.form.envios = req.data.data.envios;
                }
            })
        },
        updateContrato(file, response) {
            this.form.anexo_contrato = response.file;
        },
        limparContrato() {
            this.form.data_contrato = null;
            this.form.anexo_contrato = null;
            this.form.vencimento_contrato = null;
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
        this.fornecedores = listFornecedores.data.data; 

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(this.$route.query.novo) this.form.ativo = true;
            if(!this.form.ativo) this.inicialmenteDesativada = true;
            if(this.form.tipo) this.tipo = this.form.tipo;
        }
    },
}
</script>